//! moment.js locale configuration
//! locale : russian (ru)
//! author : Viktorminator : https://github.com/Viktorminator
//! Author : Menelion Elensúle : https://github.com/Oire

import moment from 'moment';

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

function plural(forms: any, num: any) {
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
    ? forms[1]
    : forms[2];
}
function relativeTimeWithPlural(num: any, withoutSuffix: any, key: any) {
  const format = {
    mm: withoutSuffix
      ? ['минута', 'минуты', 'минут']
      : ['минуту', 'минуты', 'минут'],
    hh: ['час', 'часа', 'часов'],
    dd: ['день', 'дня', 'дней'],
    MM: ['месяц', 'месяца', 'месяцев'],
    yy: ['год', 'года', 'лет'],
  };

  if (key === 'm') {
    return withoutSuffix ? 'минута' : 'минуту';
  } else {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `${num} ${plural(format[key], +num)}`;
  }
}
function monthsCaseReplace(m: any, format: any) {
  const months = {
    nominative: [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ],
    accusative: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  };
  const nounCase = /D[oD]?(\[[^\[\]]*\]|\s+)+MMMM?/.test(format)
    ? 'accusative'
    : 'nominative';

  return months[nounCase][m.month()];
}
function monthsShortCaseReplace(m: any, format: any) {
  const monthsShort = {
    nominative: [
      'янв',
      'фев',
      'март',
      'апр',
      'май',
      'июнь',
      'июль',
      'авг',
      'сен',
      'окт',
      'ноя',
      'дек',
    ],
    accusative: [
      'янв',
      'фев',
      'мар',
      'апр',
      'мая',
      'июня',
      'июля',
      'авг',
      'сен',
      'окт',
      'ноя',
      'дек',
    ],
  };
  const nounCase = /D[oD]?(\[[^\[\]]*\]|\s+)+MMMM?/.test(format)
    ? 'accusative'
    : 'nominative';

  return monthsShort[nounCase][m.month()];
}
function weekdaysCaseReplace(m: any, format: any) {
  const weekdays = {
    nominative: [
      'воскресенье',
      'понедельник',
      'вторник',
      'среда',
      'четверг',
      'пятница',
      'суббота',
    ],
    accusative: [
      'воскресенье',
      'понедельник',
      'вторник',
      'среду',
      'четверг',
      'пятницу',
      'субботу',
    ],
  };
  const nounCase = /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?\] ?dddd/.test(format)
    ? 'accusative'
    : 'nominative';

  return weekdays[nounCase][m.day()];
}

moment.defineLocale('ru', {
  months: monthsCaseReplace,
  monthsShort: monthsShortCaseReplace,
  weekdays: weekdaysCaseReplace,
  weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  monthsParse: [
    /^янв/i,
    /^фев/i,
    /^мар/i,
    /^апр/i,
    /^ма[й|я]/i,
    /^июн/i,
    /^июл/i,
    /^авг/i,
    /^сен/i,
    /^окт/i,
    /^ноя/i,
    /^дек/i,
  ],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY г.',
    LLL: 'D MMMM YYYY г., HH:mm',
    LLLL: 'D MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'Д.М.ГГГГ',
    LT: 'Ч:мм',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[Сегодня в] LT',
    nextDay: '[Завтра в] LT',
    lastDay: '[Вчера в] LT',
    nextWeek() {
      return this.day() === 2 ? '[Во] dddd [в] LT' : '[В] dddd [в] LT';
    },
    lastWeek(now: any) {
      if (now.week() !== this.week()) {
        switch (this.day()) {
          case 0:
            return '[В прошлое] dddd [в] LT';
          case 1:
          case 2:
          case 4:
            return '[В прошлый] dddd [в] LT';
          case 3:
          case 5:
          case 6:
            return '[В прошлую] dddd [в] LT';
          default:
            return '';
        }
      } else {
        if (this.day() === 2) {
          return '[Во] dddd [в] LT';
        } else {
          return '[В] dddd [в] LT';
        }
      }
    },
    sameYear: 'llll [в] LT',
    sameElse: 'll [в] LT',
  }),
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    m: relativeTimeWithPlural,
    mm: relativeTimeWithPlural,
    h: 'час',
    hh: relativeTimeWithPlural,
    d: 'день',
    dd: relativeTimeWithPlural,
    M: 'месяц',
    MM: relativeTimeWithPlural,
    y: 'год',
    yy: relativeTimeWithPlural,
  },
  meridiemParse: /ночи|утра|дня|вечера/i,
  isPM(input) {
    return /^(дня|вечера)$/.test(input);
  },
  meridiem(hour, minute, isLower) {
    if (hour < 4) {
      return 'ночи';
    } else if (hour < 12) {
      return 'утра';
    } else if (hour < 17) {
      return 'дня';
    } else {
      return 'вечера';
    }
  },
  ordinalParse: /\d{1,2}-(й|го|я)/,
  // @ts-expect-error TS(2322): Type '(num: any, period: any) => any' is not assig... Remove this comment to see the full error message
  ordinal(num: any, period: any) {
    switch (period) {
      case 'M':
      case 'd':
      case 'DDD':
        return `${num}-й`;
      case 'D':
        return `${num}-го`;
      case 'w':
      case 'W':
        return `${num}-я`;
      default:
        return num;
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7, // The week that contains Jan 1st is the first week of the year.
  },
});
